document, body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.MuiFormControl-root {
	margin-top: 0.5rem !important;
}

.MuiCard-root h1 {
	background-color: #66a832;
	padding: 1rem;
  margin: -1rem -1rem 1rem -1rem;
  font-size: 1.5rem;
}

.MuiCard-root h1 .MuiFormControl-root * {
	color: white;
}

.MuiCard-root h1 .MuiFormControl-root .MuiInput-underline:before {
	border-color: white;
}

.MuiCard-root h1 .print-only {
  color: white;
  font-size: 1.25rem;
  font-weight: normal;
}

.print-only {
  display: none;
}

@media print {
  document, body {
    font-size: 12px;
  }

	.MuiCard-root > h1 {
		padding: 0.5rem;
	}
  .MuiFormControl-root {
    display: none !important;
  }
  .print-only {
      display: block !important;
  }
}