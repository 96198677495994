.adventure {
	margin-bottom: 6rem;
}

.adventure > .details {
	padding: 1rem;
	box-shadow: none;
}

.adventure .sidebar {
	width: 10rem;
	height: 100%;
	margin-right: 1rem;
	float: left;
}

.adventure .sidebar .MuiButton-root {
	margin-top: 1rem;
}

.adventure .monster-list {
	width: calc(100% - 11rem);
	float: left;
}

.adventure .footer {
	position: fixed;
	bottom: 0;
	padding: 1rem;
	text-align: center;
	margin: 1rem calc(50% - 30rem);
	width: 60rem;
}

.adventure .footer .item {
	padding: 0 1rem;
}

.adventure .footer .title {
	padding: 0.5rem;
	background-color: #dddddd;
	border-radius: 0.25rem;
	display: inline-block;
	margin-left: 1rem;
}

.adventure .footer .title .name {
	text-transform: capitalize;
}

.adventure .footer .title.deletable {
	background-color: #66a832;
	color: white;
	cursor: pointer;
}

@media print {
	.adventure > .MuiCard-root > h1 {
		padding: 2rem 2rem 1rem 2rem;
		margin-bottom: 0;
	}	

	.adventure .details {
		padding: 0;
	}

    .adventure .sidebar,
    .adventure .footer {
        display: none;
    }

    .adventure .monster-list {
        width: 100%;
    }
}