.monster {
	margin: 1rem;
	width: 20rem;
	padding: 1rem;
	float: left;
}

.monster h1 > :first-child {
	width: calc(100% - 4rem)
}

.monster h1 > :nth-child(2) {
	width: 3rem;
	float: right;
	text-align: center;
}

.monster .MuiButton-root {
	display: block;
	margin: 1rem auto 0 auto;
}

.monster .core {
	margin: 0 auto;
}

.monster .core th {
	font-size: 1.5rem;
}

.monster .core td {
	text-align: center;
	padding: 0 0.5rem;
}

.monster.ghost  h1 {
	background-color: #5f71d5;
}

@media print {
    .monster {
		width: 19.75rem;
		margin: 0;
		margin-top: 1rem;
		break-inside: avoid;
	}

	.monster:nth-child(2n) {
		float: right;
		margin-left: 1rem;
	}
}